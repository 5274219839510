import Vue from "vue";
// Components
import Logo from "@takamol/nawa-library/src/components/logo/Logo.vue";
import MqLayout from "@takamol/nawa-library/src/components/mq-layout/MqLayout.vue";
// Icons
import IconTwitter from "@takamol/nawa-library/src/components/icons/IconTwitter.vue";
import IconYoutube from "@takamol/nawa-library/src/components/icons/IconYoutube.vue";
// eslint-disable-import/no-duplicates
import IconIn from "@takamol/nawa-library/src/components/icons/IconYoutube.vue";

export const setupGlobalComponents = () => {
  Vue.component("Logo", Logo);
  Vue.component("MqLayout", MqLayout);
};

export const setupGlobalIcons = () => {
  Vue.component("IconTwitter", IconTwitter);
  Vue.component("IconYoutube", IconYoutube);
  Vue.component("IconIn", IconIn);
};
